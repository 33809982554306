@mixin image-replacement($background-image, $width, $height) {
  display: block;
  width: $width;
  height: $height;
  overflow: hidden;
  text-indent: -99999px;
  background-size: contain;
  background-image: $background-image;
  background-repeat: no-repeat;
  background-position: center;
}

@function decimal-round($number, $digits: 0, $mode: round) {
  $n: 1;
  // $number must be a number
  @if type-of($number) != number {
    @warn '#{ $number } is not a number.';
    @return $number;
  }
  // $digits must be a unitless number
  @if type-of($digits) != number {
    @warn '#{ $digits } is not a number.';
    @return $number;
  } @else if not unitless($digits) {
    @warn '#{ $digits } has a unit.';
    @return $number;
  }
  @for $i from 1 through $digits {
    $n: $n * 10;
  }
  @if $mode == round {
    @return calc(round($number * $n) / $n);
  } @else if $mode == ceil {
    @return calc(ceil($number * $n) / $n);
  } @else if $mode == floor {
    @return calc(floor($number * $n) / $n);
  } @else {
    @warn '#{ $mode } is undefined keyword.';
    @return $number;
  }
}

@function rem($value, $precision: 2) {
  @return #{decimal-round(calc($value / $base-font-size), $precision)}rem;
}

@mixin clearfix {
  &:after {
    content: ' ';
    display: block;
    clear: both;
  }
}

@mixin logo-lockup {
  @include image-replacement(url('../images/logo.svg'), 275px, 28.6px);
}

@mixin sm {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin xs {
  @media screen and (max-width: 500px) {
    @content;
  }
}

@mixin animated {
  transition-duration: 0.6s;
  transition-timing-function: ease-in;
}
