@import './variables';
@import './mixins';

* {
  box-sizing: border-box;
}

// ie10 select styling
select::-ms-expand {
  display: none;
}

html,
body,
#app {
  margin: 0;
  padding: 0;
}

html {
  background-color: #0b2137;
  height: 100vh;
  color: $text-color;
  font-size: $base-font-size;
  font-family: 'Crimson Text', serif;
}

.app-container {
  height: 100%;
  font-family: 'Crimson Text', serif;
  font-size: 1rem;
  line-height: 1.15;
}

.logo {
  width: 300px;
}

.pointer {
  cursor: pointer;
}

.underline-offset {
  text-underline-offset: 12px;
}

#content {
  background: white;
  padding: rem(75px);

  @include sm {
    padding: rem(25px) rem(15px);
  }

  > div {
    max-width: 750px;
    margin: 0 auto;
  }

  #contact-us {
    text-align: center;
    margin: 1.5rem 0;
  }
}

.foreword .tip {
  display: block;
  float: none;
  text-align: center;
}

.split {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  .sidenote {
    margin: rem(30px) 0 0 rem(60px);
    flex: 1 0 0;

    @include sm {
      margin: 0;
    }
  }

  @include sm {
    display: block;

    > * {
      width: 100%;
    }
  }
}

button,
.button {
  background: $button-color;
  padding: rem(6px) rem(20px) rem(8px);
  color: white;
  font-family: 'Crimson Text', serif;
  border: none;
  border-radius: rem(6px);
  cursor: pointer;

  &:hover {
    background-color: darken($button-color, $hover-change);
  }
}

.field {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin: rem(15px) 0;

  > div {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: rem(5px);
  }

  @include sm {
    flex-direction: column;
    align-items: flex-start;

    > * {
      width: 100%;
    }

    > div {
      margin-left: 0;
    }
  }
}

.form-copy {
  @include clearfix;

  padding-left: rem($label-width);

  @include sm {
    padding-left: 0;
  }

  .tip {
    float: left;

    @include sm {
      float: none;
    }
  }

  button,
  .button {
    float: right;

    @include sm {
      float: none;
      margin: rem(10px) 0;
    }
  }
}

input,
select {
  font-family: 'Crimson Text', serif;
  padding: rem(7px) rem(12px);
  border: 1px solid #ddd;
  //width: rem(310px);
  margin-bottom: rem(5px);
  background: white;
  -webkit-appearance: none;
  width: 100%;

  @include sm {
    //width: 100%;
    margin: rem(5px) 0;
  }
}

select {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAEACAMAAABrrFhUAAAAxlBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADa+ousAAAAQXRSTlMAAQUHCAkODxMUFhcYGRscHiAhIiMlKCsuMDc4OkdLT1xeY2ZnaGl7foCIjpiisL7FyM7P0dPV4unr7e/x8/X5+2BqCiIAAAO8SURBVHja7d3pVhNBFIXRQkU04gCoiDgLlgOoiCNCTL3/SykRAyY9VHXXcM/tc37fWr329wJtDMdxHMdxHMdxHMdxHMdxHMdxHMdxHMdxHMdxHMdxHMdxHMdxHMdxnPItP3q+8/iaMtSNJ7vPHl72ubzyzk338bYi/r3Dv6g3l1pPb/1w//ZUjf/FzPTtesvp3Yk730slfnvBNL7p79dSwP5naiww59dRwM6ZGgos+DUUsAum8aq/H7+ArTDVFKj0oxewlabKAjV+7AK2xlRRoNaPXMDWmhYKNPhxC9gG01yBRj9qAdtoOln192MWsC2mCwVa/YgFbKtpVmC93Y9XwHqYTkbT05Wxc+oKWC/T9+XT2wPn1BWwnqb3f263nFNXwHqbNozZc+oK+Pvda2OOnLYCAX73yRjnlBUI8bsTY46VFQjyu8/G7DtVBcL87q0x205TgUC/u2/M0ldFBUL9H04fjSZqCoT6j69On206JQVC/b/unD18EFpgR4d/bfZURYEefhUFevkVFOjphy/Q2w9eIIIfukAUP3CBSH7YAtH8oAUi+iELRPUDFojshysQ3Q9WIIEfqkASP1CBRH6YAsn8IAUS+iEKJPUDFEjsF18guV94gQx+0QWy+AUXyOQXWyCbX2iBjP4OBXZ1+QUWyOwXVyC7X1iBUP9kLcJHBRUo4hdUoJBfTIFifiEFCvpFFCjqF1CgsL94geL+wgUE+IsWEOEvWECIv1gBMf5CBQT5ixQQ5S9QQJg/ewFx/swFBPqzFhDpz1hAqD9bAbH+TAUE+7MUEO3vUOCVLn/yAuL9iQsA+JMWgPAnLADiT1YAxp+oAJA/SYFg/7oxmgqA+aMXgPNHLgDoj1oA0h+xAKg/WgFYf6QCwP4oBaD9EQqA+3sXgPf3LKDA36uACn+PAkr8nQuo8XcsoMjfqYAqf4cCh7r8HQoo8ycuAOBPWgDCn7AAiD9ZARh/ogJA/iQFoPwJCoD5oxeA80cuAOiPWgDSH7EAqD9agcmGMUMuAOyPUgDaH6EAuL93AXh/zwIK/L0KqPD3KKDE37mAGn/HAor8nQqo8ncooMwfXECdP7CAQn9QAZX+gAJK/d4F1Po9Cyj2exVQ7fcooNzfWkC9v6XAAPyNBQbhbygwEH9tgcH4awoMyF9ZYFD+igID8y8UGJx/rsAA/cZsnv/veLxmhrjRlzP/wYoZ5pa293+6o70tw3Ecx3Ecx3Ecx3Ecx3Ecx3Ecx3Ecx3Ecx3Ecx3Ecx3Ecx3Ecx3Ecx3FR9htHJm15FpSzwwAAAABJRU5ErkJggg==');
  background-repeat: no-repeat;
  background-position: center right rem(10px);
  padding-right: rem(20px);
  background-size: 16px 16px;
  background-color: darken(white, $hover-change);
}

.field.error {
  input,
  select {
    border-color: $error-color;
  }

  span.error {
    color: $error-color;
  }
}

.sidenote {
  h4,
  p {
    margin: 0;
    padding: 0;
  }

  p {
    margin: rem(5px) 0 rem(10px);
  }

  h4 {
    font-weight: normal;
    font-size: rem(18px);
  }
}

a,
a:hover {
  color: $text-color;
}

.lockup {
  @include logo-lockup;
}

.content {
  margin: 0 auto;
}

header {
  position: relative;
  background-image: radial-gradient(ellipse at center, #1d588c 50%, #143459 100%);
  padding: rem(15px) rem(20px) rem(30px);
  height: rem(305px);

  @include sm {
    height: rem(200px);
  }

  &:before {
    content: ' ';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../images/header-bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.25;
    z-index: 1;
  }

  .content {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;

    h1 {
      font-weight: 600;
      text-align: center;
    }

    > * {
      flex-shrink: 0;
    }
  }

  &:after {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    height: 30%;
    width: 100%;
    line-height: 0;
    max-height: rem(250px);
  }

  h1 {
    font-size: rem(36px);
    color: white;
    text-align: center;
    font-weight: normal;
    margin: 0;
    padding: 0;
  }

  h2 {
    font-size: rem(36px);
  }
}

footer {
  $footer-indent: rem(30px);

  color: white;
  font-size: rem(18px);
  padding: rem(65px) rem(20px);

  p,
  ul,
  li {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  ul {
    margin: rem(40px) 0 rem(20px);
  }

  li {
    a {
      color: white;
      text-decoration: none;

      &:hover {
        color: darken(white, $hover-change);
        text-decoration: underline;
      }
    }
  }

  .indent {
    margin: 0 $footer-indent;
  }

  .copyright {
    margin: rem(15px) 0;
    font-size: rem(14px);
  }

  .disclaimer {
    font-size: rem(13px);
    margin: rem(15px) 0 rem(25px);
  }
}

.legal {
  margin-top: rem(40px);
  font-size: rem(12px);

  p,
  ul {
    margin: 0 0 rem(30px);
    line-height: 1.7;
  }

  ul {
    padding-left: rem(15px);
  }

  h4 {
    font-size: rem(12px);
    margin: 0;
    padding: 0;
    font-weight: bold;
  }
}

.success.message {
  padding: 0.75rem 1rem;
  background: #c2e8c2;
  border-radius: 4px;
  color: darkgreen;
  margin: 0 0 2rem;
  font-size: rem(15px);

  p {
    padding: 0;
    margin: 0;
  }
}

time {
  font-weight: 700;
  margin-right: rem(20px);

  > * {
    display: block;
    text-align: center;
    line-height: 1;
  }

  .month {
    text-transform: uppercase;
  }

  .day {
    font-size: rem(29px);
    line-height: 0.8;
  }
}

.tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0 0 rem(55px);

  @include xs {
    margin-bottom: rem(30px);
  }

  li {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  a {
    display: block;
    text-transform: uppercase;
    font-size: rem(20px);
    font-weight: 600;
    padding: 0 0 rem(10px);
    margin: 0 rem(20px);
    text-decoration: none;
    border-bottom: 2px solid white;

    &:hover,
    &.active {
      text-decoration: none;
      border-bottom-color: $text-color;
    }

    @include xs {
      font-size: rem(12px);
    }
  }
}

.page-thanks .thanks {
  margin-bottom: rem(150px);

  h3 {
    font-weight: normal;
    padding: 0;
    margin: 0 0 1rem;
    font-size: rem(24px);
    color: $button-color;
  }

  h3,
  p {
    text-align: center;
  }
}

.page-announcements {
  .announcement {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;

    > * {
      display: block;
    }

    .copy p:first-child {
      margin-top: 0;
    }

    .copy {
      flex-shrink: 1;
    }
  }

  hr {
    &:first-child {
      display: none;
    }

    display: block;
    height: 1px;
    border: none;
    background: $text-color;
    margin: rem(40px) auto;
    max-width: rem(250px);
    width: 100%;
  }
}

.page-financial-documents {
  .document {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: rem(15px) rem(10px) rem(10px);
    white-space: normal;

    > * {
      display: block;
    }

    &:nth-child(odd) {
      background-color: #e4e4e4;
    }

    img {
      margin: 0 rem(20px) 0 0;
      width: 21px;
      height: 26px;
    }

    .copy {
      font-weight: 700;
      flex-shrink: 1;

      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.home-container {
  background: $bg-color;
  background-image: radial-gradient(ellipse at center, #1d588c 50%, #143459 100%);
  font-size: rem(14px);

  @media screen and (max-width: 600px) {
    font-size: rem(12px);
  }

  &,
  body,
  .markets-logo {
    height: 100%;
  }

  .markets-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    padding: 1rem;
    height: 100vh;

    > div {
      width: 100%;
      text-align: center;
    }
  }

  p {
    font: 1rem/1 'Open Sans';
    color: white;
    opacity: 0.5;
    padding: 0;
    margin: 0;
    margin-top: 1.25rem;
    text-align: center;

    @media screen and (max-width: 600px) {
      margin-top: 0.5rem;
    }
  }

  img {
    display: block;
    width: 100%;
    max-width: 608px;
    margin: 0 auto;
  }
}

#menu {
  position: absolute;
  z-index: 100;
  width: 100%;
  top: 0;
  left: 0;
  height: 0;
  color: white;
  font-size: rem(18px);
  background-color: rgba(#0b2137, 0);
  font-weight: bold;

  > div {
    position: absolute;
    top: rem(10px);
    right: rem(15px);

    @include sm {
      width: 100%;
      right: 0;
    }
  }

  .toggle {
    text-decoration: none;
    cursor: pointer;
    text-align: right;
    display: block;

    @include sm {
      margin-right: rem(15px);
    }

    &.close {
      display: none;
    }
  }

  .toggle,
  a {
    padding: rem(10px) rem(5px);
  }

  a {
    color: white;
    text-decoration: none;
    display: block;
    text-align: right;

    @include sm {
      text-align: center;
    }
  }

  a:hover,
  .toggle:hover {
    color: darken(white, $hover-change);
  }

  .close {
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjEzcHgiIGhlaWdodD0iMTNweCIgdmlld0JveD0iMCAwIDEzIDEzIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA0MSAoMzUzMjYpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPkNsb3NlIFg8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iV2VsY29tZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IkRlc2t0b3AtMDMtQ29weS0xMyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTkzNi4wMDAwMDAsIC05NC4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPGcgaWQ9IkhlYWRlci1NZW51IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMi4wMDAwMDAsIDAuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8cG9seWdvbiBpZD0iQ2xvc2UtWCIgcG9pbnRzPSI5NDkuNjIzMDI2IDk0IDk0NC40OTk5MzIgOTkuMTIyNjM3OSA5MzkuMzc3Mjk0IDk0IDkzOCA5NS4zNzY4MzczIDk0My4xMjMwOTQgMTAwLjQ5OTkzMiA5MzggMTA1LjYyMjU2OSA5MzkuMzc3Mjk0IDEwNi45OTk4NjMgOTQ0LjQ5OTkzMiAxMDEuODc2NzY5IDk0OS42MjMwMjYgMTA2Ljk5OTg2MyA5NTAuOTk5ODYzIDEwNS42MjI1NjkgOTQ1Ljg3NzIyNSAxMDAuNDk5OTMyIDk1MC45OTk4NjMgOTUuMzc2ODM3MyI+PC9wb2x5Z29uPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=');
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: rem(25px);
  }

  ul {
    margin: 0;
    padding: 0;
    height: 0;
    overflow: hidden;

    @include sm {
      margin-top: rem(30px);
    }

    &,
    li {
      list-style-type: none;
    }

    li {
      border-top: 1px solid white;

      @include sm {
        &:first-child {
          border-top: none;
        }
      }
    }
  }

  &.open {
    width: 100%;
    height: 100%;

    .toggle {
      display: none;

      &.close {
        display: block;
      }
    }

    ul {
      display: block;
    }
  }
}
